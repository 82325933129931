
import { SearchBar, SearchBarItem } from '@/components/search'
import { Table, TableData, TableHeader, TableRow } from '@/components/table'
import Badge from '@/components/status/Badge.vue'
import Paging from '@/components/actions/Paging.vue'
import UserFilter from 'tradingmate-modules/src/filters/UserFilter'
import { DropdownListItem } from 'tradingmate-modules/src/forms'
import { PagedResultSet } from 'tradingmate-modules/src/models/api/common'
import AdminUserModel from 'tradingmate-modules/src/models/api/users/AdminUserModel'
import UserRole from 'tradingmate-modules/src/models/api/users/UserRole'
import { Services } from 'tradingmate-modules/src/services'
import { Vue, Component } from 'vue-property-decorator'
import { CategorySelector, InputSelect, InputText, ZoneSelector } from '@/components/inputs'

@Component({
  components: {
    Table,
    TableHeader,
    TableRow,
    TableData,
    SearchBar,
    SearchBarItem,
    ZoneSelector,
    CategorySelector,
    Paging,
    Badge,
    InputText,
    InputSelect
  }
})

export default class AdminDatabaseUsers extends Vue {
  private users: PagedResultSet<AdminUserModel> | null = null
  private filter: UserFilter = new UserFilter()
  private loading = false;
  private isExporting = false;
  private UserRole = UserRole

  mounted (): void {
    this.updateFilter()
    this.GetUsers()
  }

  defaultRole: DropdownListItem = { Key: 'all', Label: 'All', Value: [UserRole.NONE] };
  roles: DropdownListItem[] = [
    { Key: UserRole[0], Label: 'Any', Value: UserRole.NONE },
    { Key: UserRole[1], Label: 'Webmaster', Value: UserRole.WEBMASTER },
    { Key: UserRole[2], Label: 'User', Value: UserRole.USER },
    { Key: UserRole[3], Label: 'Franchisee', Value: UserRole.FRANCHISEE }
  ]

  search (): void {
    this.updateQuery()
    this.updateFilter()
    this.GetUsers()
  }

  updateQuery (): void {
    this.$router.push({
      query: {
        email: this.filter.Email?.length ? this.filter.Email : undefined,
        firstName: this.filter.FirstName?.length ? this.filter.FirstName : undefined,
        lastName: this.filter.LastName?.length ? this.filter.LastName : undefined,
        role: this.filter.Role ? this.filter.Role.toString() : undefined,
        page: this.filter.Page?.toString().length ? this.filter.Page?.toString() : undefined,
        branchId: this.filter.BranchId?.length ? this.filter.BranchId : undefined
      }
    })
  }

  updateFilter (): void {
    this.filter.Email = this.$route.query.email?.toString()
    this.filter.FirstName = this.$route.query.firstName?.toString()
    this.filter.LastName = this.$route.query.lastName?.toString()
    console.log('this.$route.query.roles')
    console.log(this.$route.query.roles)
    this.filter.Role = this.$route.query.role ? parseInt(this.$route.query.role.toString()) : undefined
    this.filter.BranchId = this.$route.query.branchId?.toString()
  }

  GetUsers (): void {
    this.loading = true
    Services.API.Admin.Users.Search(this.filter)
      .then((returnData) => { this.users = returnData })
      .finally(() => { this.loading = false })
  }

  handlePaged (page: number) {
    this.filter.Page = page
    this.search()
  }

  handleExportClick () {
    this.updateFilter()
    this.isExporting = true
    Services.API.Admin.Users.ExportUsers(this.filter)
      .then((response) => {
        const link = document.createElement('a')
        link.href = URL.createObjectURL(response)
        link.download = 'users.csv'
        link.click()
        URL.revokeObjectURL(link.href)
      }).finally(() => {
        this.isExporting = false
      })
  }

  viewBranches (userId: string): void {
    this.$router.push({ name: 'Admin Database Businesses', query: { userId: userId } })
  }
}

